@import "../../variables.scss";

svc-page-navigator,
.svc-page-navigator {
  display: flex;
  flex-direction: column;
}

.svc-page-navigator__button-icon {
  display: block;
  width: var(--ctr-page-navigator-button-icon-width, calcSize(3));
  height: var(--ctr-page-navigator-button-icon-height, calcSize(3));
  padding: var(--ctr-page-navigator-button-padding, calcSize(1.25));
  border-radius: var(--ctr-page-navigator-button-corner-radius, 50%);
  overflow: visible;
  cursor: pointer;
  transition: background-color $creator-transition-duration;

  .sv-svg-icon {
    width: var(--ctr-page-navigator-button-icon-width, calcSize(3));
    height: var(--ctr-page-navigator-button-icon-height, calcSize(3));
  }

  use {
    fill: var(--ctr-page-navigator-button-icon-color, $foreground-light);
    transition: fill $creator-transition-duration;
  }
}

.svc-page-navigator__button {
  --ctr-page-navigator-button-double-padding: calc(2 * var(--ctr-page-navigator-button-padding));
  --ctr-page-navigator-button-width: calc(
    var(--ctr-page-navigator-button-icon-width) + var(--ctr-page-navigator-button-double-padding)
  );
  --ctr-page-navigator-button-height: calc(
    var(--ctr-page-navigator-button-icon-height) + var(--ctr-page-navigator-button-double-padding)
  );
  width: var(--ctr-page-navigator-button-width, calcSize(5.5));
  height: var(--ctr-page-navigator-button-height, calcSize(5.5));

  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  &:hover,
  &:focus-visible {
    outline: none;

    .svc-page-navigator__button-icon {
      background-color: var(--ctr-page-navigator-button-background-color-hovered, $primary-light);

      use {
        fill: var(--ctr-page-navigator-button-icon-color-hovered, $primary);
      }
    }
  }
}

.svc-page-navigator__button--pressed, .svc-page-navigator__button:active {
  .svc-page-navigator__button-icon {
    background: var(--ctr-page-navigator-button-background-color-pressed, $primary-light);
    opacity: var(--ctr-page-navigator-button-opacity-pressed, 0.5);

    use {
      fill: var(--ctr-page-navigator-button-icon-color-hovered, $primary);
    }
  }
}

.svc-page-navigator__button--active, .svc-page-navigator__button--active:hover  {
  .svc-page-navigator__button-icon {
    background: var(--ctr-page-navigator-button-background-color-checked, transparent);
    use {
      fill: var(--ctr-page-navigator-button-icon-color-checked, $primary);
    }
  }
}

.svc-page-navigator__popup {
  min-width: calcSize(12.5);
}

.svc-tab-designer--with-page-navigator {
  .svc-tab-designer_content {
    margin-left: calcSize(6.5);
    margin-right: calcSize(6.5);
    width: calc(100% - calcSize(13));
  }
}

.svc-tab-designer__page-navigator {
  position: absolute;
  padding: calcSize(16.5) 0;
  top: 0;
  bottom: 0;
  right: var(--ctr-page-navigator-padding-right, calcSize(1.5));
}

.svc-page-navigator {
  padding: var(--ctr-page-navigator-padding-top, calcSize(1.5)) 0
    var(--ctr-page-navigator-padding-bottom, calcSize(1.5)) var(--ctr-page-navigator-padding-left, calcSize(1.5));
}

.svc-creator__toolbox--right,
[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .svc-tab-designer__page-navigator {
    right: unset;
    left: var(--ctr-page-navigator-padding-left, calcSize(1.5));
  }

  .svc-page-navigator {
    padding: var(--ctr-page-navigator-padding-top, calcSize(1.5)) var(--ctr-page-navigator-padding-right, calcSize(1.5))
      var(--ctr-page-navigator-padding-bottom, calcSize(1.5)) 0;
  }
}

.svc-tab-designer--bypage-mode {
  .svc-tab-designer_content {
    display: flex;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  .svc-tab-designer__page-navigator {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    .svc-page-navigator {
      padding-right: 0;
    }
  }
}