@import "../variables.scss";

.svc-link__button {
  @include ctrDefaultBoldFont;
}

.svc-question-link__set-button {
  cursor: pointer;
}
.svc-link-value-button {
  margin-left: calcSize(-2);
  border-radius: var(--ctr-actionbar-button-corner-radius, calcSize(12.5));
}
.svc-question-link__clear-button {
  color: $red;
  cursor: pointer;
}
.svc-question-link__clear-button:focus,
.svc-question-link__clear-button:hover {
  background-color: $red-light;
}
