@import "../variables.scss";

.sd-element__header {
  .svc-string-editor {
    display: inline-block;
    max-width: 100%;
  }
}

.svc-string-editor {
  position: static;

  [contenteditable="true"] {
    user-select: text;
    -webkit-user-select: text;
  }

  .sv-string-editor {
    position: relative;
    outline: none;
    z-index: 12;

    &[aria-placeholder]:empty:before {
      content: attr(aria-placeholder);
      color: var(--ctr-survey-page-header-title-color-placeholder, $foreground-light);
      transition: color $creator-transition-duration;
    }
  }

  .sv-string-editor--html,
  .sv-string-editor:focus-within {
    white-space: pre-wrap;
  }

  &.svc-string-editor--hidden {
    display: none;
  }
}

.svc-string-editor__content {
  display: inline-block;
  position: relative;
  z-index: 10;
  width: 100%;
}

.svc-matrix-cell {
  .svc-string-editor__content {
    width: auto;
  }
}

.svc-string-editor__content:focus-within .svc-remaining-character-counter {
  display: inline-block;
}

.svc-string-editor__input {
  display: flex;
}

.svc-remaining-character-counter {
  @include ctrSmallFont;
  display: none;
  position: relative;
  outline: none;
  z-index: 20;
  padding: 0px;
  vertical-align: top;
  margin-inline-start: calcSize(2);
  color: $foreground-light;
}

.svc-string-editor__border {
  position: absolute;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: right;
  line-height: 0px;
  transition: opacity $creator-transition-duration;
  box-shadow: 0px 0px 0px 0px $border-inside;
  border-radius: 3px;
  opacity: 0;

  &.svc-string-editor__border--hover {
    top: 0;
    bottom: 0;
    left: calcSize(-0.5);
    right: calcSize(-0.5);
    //right: calcSize(-4.5); // if need pencil icon
    box-shadow: 0px 0px 0px calcSize(0.25) $border-inside;
    transition-delay: 0ms;
  }

  &.svc-string-editor__border--focus {
    top: calcSize(-0.5);
    bottom: calcSize(-0.5);
    left: calcSize(-1);
    right: calcSize(-1);
    box-sizing: content-box;
    background-color: $background;
    box-shadow: $shadow-medium, 0px 0px 0px calcSize(0.25) $primary;
  }
}

.svc-string-editor__error {
  @include ctrDefaultFont;
  margin-left: calcSize(1);
  color: red;
  display: none;
}

.svc-string-editor__controls {
  position: absolute;
  left: 100%;
  height: 100%;
  width: calcSize(2);
  top: 0;
  padding-left: calcSize(2);
  z-index: 12;
  display: none;
  align-items: center;
  justify-content: center;
  line-height: 0px;
}

.svc-string-editor:hover:not(.svc-string-editor--readonly):not(:focus-within) {
  .svc-string-editor__border--hover {
    opacity: 1;
    transition-delay: 200ms;
  }

  .svc-string-editor__border--focus {
    transition: none;
  }
}

.svc-creator--disable-animations {
  .svc-string-editor:hover:not(.svc-string-editor--readonly):not(:focus-within) {
    .svc-string-editor__border--hover {
      transition-delay: 0ms;
    }
  }
}

.svc-string-editor:focus-within {
  .svc-string-editor__border--focus {
    opacity: 1;
  }

  .svc-string-editor__border--hover {
    transition: none;
  }
}

.svc-string-editor.svc-string-editor--error:focus-within {
  .svc-string-editor__border--focus {
    box-shadow: $shadow-medium, 0px 0px 0px calcSize(0.25) $red;
  }
}

.sd-boolean {
  .sv-string-editor:focus-within {
    white-space: nowrap;
  }
}

.sd-rating {
  .sd-rating__item:focus-within {

    &:not(.sd-rating__min-text),
    &:not(.sd-rating__max-text) {
      .svc-string-editor:focus-within {
        .svc-string-editor__border {
          display: none;
        }
      }
    }
  }
}

.sd-table__cell--column-title {
  .svc-matrix-cell:not(:focus):focus-within {
    .svc-matrix-cell--selected {
      border: calcSize(0.25) solid $primary;
      border-radius: calcSize(0.5);
      display: block;
    }
  }

  .svc-string-editor:focus-within {
    .svc-string-editor__border {
      display: none;
    }
  }
}

.sd-item__control-label {
  .svc-string-editor:focus-within {
    .svc-string-editor__border {
      left: calcSize(-0.7);
      right: calcSize(-0.7);
    }
  }
}

.svc-string-editor {

  &:hover,
  &:focus-within {
    &~.sd-question__required-text {
      visibility: hidden;
    }
  }
}

// .sv-question__header,
// .sv-title {
//   position: relative;
// }
.svc-string-editor--multiline .sv-string-editor {
  white-space: pre-wrap;
}