@import "../../variables.scss";

.svc-tabbed-menu-item {
  display: flex;
  align-items: center;
  min-height: calc(var(--ctr-menu-toolbar-button-icon-height) + var(--ctr-menu-item-padding-top) + var(--ctr-menu-item-padding-bottom));
  padding: var(--ctr-menu-item-padding-top, 20px) var(--ctr-menu-item-padding-right, 24px) var(--ctr-menu-item-padding-bottom, 20px) var(--ctr-menu-item-padding-left, 24px) ;
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow $creator-transition-duration, background-color $creator-transition-duration;

  &:hover,
  &:focus {
    background-color: var(--ctr-menu-item-background-color-hovered, $background-dim);
    box-shadow: inset 0px -1px 0px var(--ctr-menu-border-color, $border);
    outline: none;
  }
}

.svc-tabbed-menu-item--icon {
  padding-right: var(--ctr-menu-item-padding-right-icon, 16px);
  padding-left: var(--ctr-menu-item-padding-left-icon, 16px);
  display: flex;
  align-items: center;
}

.svc-tabbed-menu-item--selected {
  background: var(--ctr-menu-item-background-color, $background);
  box-shadow: inset 0px -2px 0px var(--ctr-menu-item-border-color-selected, $primary);

  &:hover,
  &:focus {
    background: var(--ctr-menu-item-background-color, $background);
    box-shadow: inset 0px -2px 0px var(--ctr-menu-item-border-color-selected, $primary);
  }
}

.svc-tabbed-menu-item--disabled {
  cursor: default;
  opacity: var(--ctr-menu-item-opacity-disabled, 0.25);

  &:hover {
    background-color: var(--ctr-menu-item-background-color, $background);
  }
}

.svc-tabbed-menu-item--hidden {
  visibility: hidden;
}

.svc-tabbed-menu-item__text.svc-tabbed-menu-item__text {
  @include ctrDefaultFont;
  white-space: nowrap;
  display: block;
  color: var(--ctr-menu-item-text-color, $foreground);
}

.svc-tabbed-menu-item--selected .svc-tabbed-menu-item__text {
  @include ctrDefaultBoldFont;
}

.svc-tabbed-menu-item__icon {
  width: var(--ctr-menu-item-icon-width, 24px);
  height: var(--ctr-menu-item-icon-height, 24px);
  fill: var(--ctr-menu-item-icon-color, $foreground-light);
}

.svc-tabbed-menu-item-container .sv-dots__item {
  width: auto;
}
.svc-tabbed-menu-item-container 
{
  .sv-list__item-icon {
    display: none;
  }
  .sv-list__item--with-icon.sv-list__item--with-icon > .sv-list__item-body {
    padding-block: calcSize(1);
  }
}