.spg-checkbox {
  cursor: pointer;
  margin-top: var(--ctr-checkbox-list-gap-vertical, calcSize(2));
}

.spg-checkbox--disabled {
  cursor: default;

  .spg-checkbox {
    cursor: default;
  }

  .spg-checkbox__caption {
    color: var(--ctr-checkbox-text-color-disabled, $foreground-light);
  }

  .spg-checkbox__rectangle {
    border: var(--ctr-checkbox-border-width, 1px) solid var(--ctr-checkbox-border-color-disabled, rgba(0, 0, 0, 0.16));
    background: var(--ctr-checkbox-background-color-disabled, #f3f3f3);
  }
}

.spg-checkbox__caption {
  @include ctrDefaultFont;
  color: var(--ctr-checkbox-text-color, $foreground);
}

.spg-checkbox__rectangle {
  background: var(--ctr-checkbox-background-color, $background);
  border: var(--ctr-checkbox-border-width, 1px) solid var(--ctr-checkbox-border-color, $border);
  border-radius: var(--ctr-checkbox-corner-radius);
  box-sizing: border-box;
  width: var(--ctr-checkbox-button-width, calcSize(3));
  height: var(--ctr-checkbox-button-height, calcSize(3));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  // padding: calcSize(0.5);
  padding: 1px 1px;
  outline: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background $creator-transition-duration, background-color $creator-transition-duration, outline $creator-transition-duration;
}

.spg-checkbox:hover {
  .spg-checkbox__rectangle {
    background-color: var(--ctr-checkbox-background-color-hovered, $background-dim);
  }
}

.spg-checkbox:active .spg-checkbox__rectangle,
.spg-checkbox__control:focus+.spg-checkbox__rectangle {
  outline: 2px solid var(--ctr-checkbox-border-color-focused, $primary);
  outline-offset: -2px;
}

.spg-checkbox .spg-checkbox__svg {
  display: block;
  opacity: 0;
  fill: transparent;
  width: var(--ctr-checkbox-button-check-mark-width, calcSize(2.5));
  height: var(--ctr-checkbox-button-check-mark-height, calcSize(2.5));
  background-repeat: no-repeat;
  background-size: calcSize(2) calcSize(2);
  transition: opacity $creator-transition-duration, fill $creator-transition-duration;
}

.spg-checkbox--checked {
  .spg-checkbox__rectangle {
    background: var(--ctr-checkbox-background-color-selected, $background);
  }

  .spg-checkbox__svg {
    opacity: 1;
    fill: var(--ctr-checkbox-button-check-mark-color, $primary);
  }
}

.spg-checkbox--disabled.spg-checkbox--checked .spg-checkbox__svg {
  fill: var(--ctr-checkbox-button-check-mark-color-disabled, $border);
}

.spg-checkbox__control {
  position: fixed;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

//todo: need to hide unneeded icons
.spg-checkbox__hidden {
  display: none;
}

.spg-selectbase {
  border: none;
  padding: 0;
  margin: 0;
}

.spg-selectbase__label {
  display: flex;
  align-items: flex-start;
  gap: var(--ctr-checkbox-gap, calcSize(1));
}

.spg-checkbox {
  .spg-question__description {
    padding: 0;
    padding-inline-start: var(--ctr-checkbox-description-text-margin-left, calcSize(4));
    color: var(--ctr-checkbox-description-text-color, $foreground-dim-light);
  }

  .spg-help-action {
    use {
      pointer-events: none;
    }
  }
}