.svc-creator {
  --lbr-spacing-x16: calc(#{$lbr-spacing-unit} * 16);
  --lbr-spacing-x15: calc(#{$lbr-spacing-unit} * 15);
  --lbr-spacing-x14: calc(#{$lbr-spacing-unit} * 14);
  --lbr-spacing-x13: calc(#{$lbr-spacing-unit} * 13);
  --lbr-spacing-x12: calc(#{$lbr-spacing-unit} * 12);
  --lbr-spacing-x11: calc(#{$lbr-spacing-unit} * 11);
  --lbr-spacing-x1050: calc(#{$lbr-spacing-unit} * 10.5);
  --lbr-spacing-x10: calc(#{$lbr-spacing-unit} * 10);
  --lbr-spacing-x9: calc(#{$lbr-spacing-unit} * 9);
  --lbr-spacing-x850: calc(#{$lbr-spacing-unit} * 8.5);
  --lbr-spacing-x8: calc(#{$lbr-spacing-unit} * 8);
  --lbr-spacing-x7: calc(#{$lbr-spacing-unit} * 7);
  --lbr-spacing-x6: calc(#{$lbr-spacing-unit} * 6);
  --lbr-spacing-x5: calc(#{$lbr-spacing-unit} * 5);
  --lbr-spacing-x4: calc(#{$lbr-spacing-unit} * 4);
  --lbr-spacing-x350: calc(#{$lbr-spacing-unit} * 3.5);
  --lbr-spacing-x3: calc(#{$lbr-spacing-unit} * 3);
  --lbr-spacing-x2: calc(#{$lbr-spacing-unit} * 2);
  --lbr-spacing-x1: calc(#{$lbr-spacing-unit} * 1);
  --lbr-spacing-x0375: calc(#{$lbr-spacing-unit} * 0.375);
  --lbr-spacing-x05: calc(#{$lbr-spacing-unit} * 0.5);
  --lbr-spacing-x250: calc(#{$lbr-spacing-unit} * 2.5);
  --lbr-spacing-x150: calc(#{$lbr-spacing-unit} * 1.5);
  --lbr-spacing-x125: calc(#{$lbr-spacing-unit} * 1.25);
  --lbr-spacing-x075: calc(#{$lbr-spacing-unit} * 0.75);
  --lbr-spacing-x025: calc(#{$lbr-spacing-unit} * 0.25);
  --lbr-spacing-x0125: calc(#{$lbr-spacing-unit} * 0.125);
  --lbr-spacing-x025n: calc(#{$lbr-spacing-unit} * -0.25);
  --lbr-spacing-x05n: calc(#{$lbr-spacing-unit} * -0.5);
  --lbr-spacing-x1n: calc(#{$lbr-spacing-unit} * -1);
  --lbr-spacing-x150n: calc(#{$lbr-spacing-unit} * -1.5);
  --lbr-spacing-x2n: calc(#{$lbr-spacing-unit} * -2);
  --lbr-spacing-x250n: calc(#{$lbr-spacing-unit} * -2.5);
  --lbr-spacing-x3n: calc(#{$lbr-spacing-unit} * -3);
  --lbr-spacing-x4n: calc(#{$lbr-spacing-unit} * -4);
  --lbr-spacing-x5n: calc(#{$lbr-spacing-unit} * -5);
  --lbr-spacing-x6n: calc(#{$lbr-spacing-unit} * -6);
  --lbr-spacing-x7n: calc(#{$lbr-spacing-unit} * -7);
  --lbr-spacing-x8n: calc(#{$lbr-spacing-unit} * -8);
  --lbr-corner-radius-x0125: calc(#{$lbr-corner-radius-unit} * 0.125);
  --lbr-corner-radius-x025: calc(#{$lbr-corner-radius-unit} * 0.25);
  --lbr-corner-radius-x0375: calc(#{$lbr-corner-radius-unit} * 0.375);
  --lbr-corner-radius-x05: calc(#{$lbr-corner-radius-unit} * 0.5);
  --lbr-corner-radius-x075: calc(#{$lbr-corner-radius-unit} * 0.75);
  --lbr-corner-radius-x1: calc(#{$lbr-corner-radius-unit} * 1);
  --lbr-corner-radius-x125: calc(#{$lbr-corner-radius-unit} * 1.25);
  --lbr-corner-radius-x150: calc(#{$lbr-corner-radius-unit} * 1.5);
  --lbr-corner-radius-x2: calc(#{$lbr-corner-radius-unit} * 2);
  --lbr-corner-radius-x250: calc(#{$lbr-corner-radius-unit} * 2.5);
  --lbr-corner-radius-x3: calc(#{$lbr-corner-radius-unit} * 3);
  --lbr-corner-radius-x350: calc(#{$lbr-corner-radius-unit} * 3.5);
  --lbr-corner-radius-x4: calc(#{$lbr-corner-radius-unit} * 4);
  --lbr-corner-radius-x5: calc(#{$lbr-corner-radius-unit} * 5);
  --lbr-corner-radius-x6: calc(#{$lbr-corner-radius-unit} * 6);
  --lbr-corner-radius-x7: calc(#{$lbr-corner-radius-unit} * 7);
  --lbr-corner-radius-x8: calc(#{$lbr-corner-radius-unit} * 8);
  --lbr-corner-radius-round: 1024px;
  --lbr-stroke-x1: calc(#{$lbr-stroke-unit} * 1);
  --lbr-stroke-x2: calc(#{$lbr-stroke-unit} * 2);
  --lbr-stroke-x3: calc(#{$lbr-stroke-unit} * 3);
  --lbr-stroke-x4: calc(#{$lbr-stroke-unit} * 4);
  --lbr-stroke-x6: calc(#{$lbr-stroke-unit} * 6);
  --lbr-stroke-x8: calc(#{$lbr-stroke-unit} * 8);
  --lbr-size-x0125: calc(#{$lbr-size-unit} * 0.125);
  --lbr-size-x025: calc(#{$lbr-size-unit} * 0.25);
  --lbr-size-x05: calc(#{$lbr-size-unit} * 0.5);
  --lbr-size-x075: calc(#{$lbr-size-unit} * 0.75);
  --lbr-size-x1: calc(#{$lbr-size-unit} * 1);
  --lbr-size-x125: calc(#{$lbr-size-unit} * 12.5);
  --lbr-size-x150: calc(#{$lbr-size-unit} * 1.5);
  --lbr-size-x2: calc(#{$lbr-size-unit} * 2);
  --lbr-size-x250: calc(#{$lbr-size-unit} * 2.5);
  --lbr-size-x3: calc(#{$lbr-size-unit} * 3);
  --lbr-size-x350: calc(#{$lbr-size-unit} * 3.5);
  --lbr-size-x4: calc(#{$lbr-size-unit} * 4);
  --lbr-size-x450: calc(#{$lbr-size-unit} * 4.5);
  --lbr-size-x5: calc(#{$lbr-size-unit} * 5);
  --lbr-size-x550: calc(#{$lbr-size-unit} * 5.5);
  --lbr-size-x6: calc(#{$lbr-size-unit} * 6);
  --lbr-size-x7: calc(#{$lbr-size-unit} * 7);
  --lbr-size-x8: calc(#{$lbr-size-unit} * 8);
  --lbr-size-x9: calc(#{$lbr-size-unit} * 9);
  --lbr-size-x10: calc(#{$lbr-size-unit} * 10);
  --lbr-size-x11: calc(#{$lbr-size-unit} * 11);
  --lbr-size-x12: calc(#{$lbr-size-unit} * 12);
  --lbr-size-x13: calc(#{$lbr-size-unit} * 13);
  --lbr-size-x14: calc(#{$lbr-size-unit} * 14);
  --lbr-size-x15: calc(#{$lbr-size-unit} * 15);
  --lbr-size-x16: calc(#{$lbr-size-unit} * 16);
  --lbr-size-x17: calc(#{$lbr-size-unit} * 17);
  --lbr-size-x18: calc(#{$lbr-size-unit} * 18);
  --lbr-size-x20: calc(#{$lbr-size-unit} * 20);
  --lbr-size-x28: calc(#{$lbr-size-unit} * 28);
  --lbr-size-x32: calc(#{$lbr-size-unit} * 32);
  --lbr-size-x40: calc(#{$lbr-size-unit} * 40);
  --lbr-size-x50: calc(#{$lbr-size-unit} * 50);
  --lbr-size-x60: calc(#{$lbr-size-unit} * 60);
  --lbr-size-x64: calc(#{$lbr-size-unit} * 64);
  --lbr-size-x80: calc(#{$lbr-size-unit} * 80);
  --lbr-size-x86: calc(#{$lbr-size-unit} * 86);
  --lbr-size-x90: calc(#{$lbr-size-unit} * 90);
  --lbr-size-max: 10000px;
}