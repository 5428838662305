@import "../../variables.scss";
@import "../../variables-layer.scss";

.svc-toolbox__item {
  display: flex;
  position: relative;
  align-items: center;
  outline: none;
  padding-top: var(--ctr-toolbox-item-padding-top, calcSize(1));
  padding-bottom: var(--ctr-toolbox-item-padding-bottom, calcSize(1));
  padding-inline-end: var(--ctr-toolbox-item-padding-right, calcSize(1.5));
  padding-inline-start: var(--ctr-toolbox-item-padding-left, calcSize(1.5));
  border-radius: var(--ctr-toolbox-item-corner-radius, calcSize(12.5));
}
.svc-toolbox__item-icon {
  display: block;
  height: var(--ctr-toolbox-item-icon-height, calcSize(3));
  width: var(--ctr-toolbox-item-icon-width, calcSize(3));
  pointer-events: none;
}
.svc-toolbox__tool {
  cursor: default;
  align-items: var(--ctr-toolbox-item-align, flex-start);
  align-self: var(--ctr-toolbox-item-align, flex-start);
  &:first-of-type > .svc-toolbox__tool-content {
    padding-block-start: 0;
    .svc-toolbox__item-banner {
      top: 50%;
    }
  }
}
.svc-toolbox__tool-content {
  display: flex;
  // padding: calcSize(0.25) 0;
  padding-block-start: var(--ctr-toolbox-group-gap, calcSize(0.5));
  flex-direction: column;
  align-items: var(--ctr-toolbox-item-align, flex-start);
  position: relative;
}

.svc-toolbox__item--has-subitems {
  padding-inline-end: calcSize(6);
}

.svc-toolbox__tool--has-subitems {
  align-items: var(--ctr-toolbox-item-align, flex-start);
  align-self: var(--ctr-toolbox-item-align, flex-start);
}

// is always visible
.svc-toolbox__item-container {
  display: flex;
  outline: none;
  use {
    fill: var(--ctr-toolbox-item-icon-color, $foreground-light);
  }
}

//is visible only on hover
.svc-toolbox__item-banner {
  @include ctrSmallBoldFont;
  opacity: 0;
  box-sizing: border-box;
  align-items: center;
  inset-inline-start: var(--thm-toolbox-item-banner-left, calcSize(-0.5));
  max-width: calcSize(100);
  padding-top: var(--ctr-tooltip-padding-top, calcSize(1.5));
  padding-inline-end: var(--ctr-tooltip-padding-right, calcSize(3.5));
  padding-bottom: var(--ctr-tooltip-padding-bottom, calcSize(1.5));
  padding-inline-start: var(--ctr-tooltip-padding-left, calcSize(5.5));
  margin-inline-end: calcSize(-2);
  white-space: nowrap;
  color: var(--ctr-toolbox-item-text-color, $foreground);
  transition: opacity $creator-transition-duration;
  background-color: $background;
  /* Shadow / Medium */
  border-radius: var(--ctr-tooltip-corder-radius, calcSize(12.5));
  box-shadow:
    var(--ctr-tooltip-shadow-1-offset-x, 0px) var(--ctr-tooltip-shadow-1-offset-y, 2px)
      var(--ctr-tooltip-shadow-1-blur, 6px) var(--ctr-tooltip-shadow-1-spread, 0px)
      var(--ctr-tooltip-shadow-1-color, rgba(0, 0, 0, 0.1)),
    var(--ctr-tooltip-shadow-2-offset-x, 0px) var(--ctr-tooltip-shadow-2-offset-y, 1px)
      var(--ctr-tooltip-shadow-2-blur, 2px) var(--ctr-tooltip-shadow-2-spread, 0px)
      var(--ctr-tooltip-shadow-2-color, transparent);
  // transition:
  //   max-width 0.1s ease-in-out,
  //   padding 0.2s ease-in-out;
  background-color: var(--ctr-tooltip-background-color, $background);
  overflow: visible;
  position: absolute;
  z-index: 200;
  top: calc(50% + var(--ctr-toolbox-group-gap, 4px) / 2);
  transform: translate(0, -50%);
  pointer-events: none;
  .svc-toolbox__item-icon {
    position: absolute;
    display: var(--ctr-toolbox-item-banner-icon-display, block);
    inset-inline-start: calcSize(1.5);
    top: calcSize(1);
  }

  &:after {
    content: " ";
    display: var(--ctr-toolbox-item-banner-beak-display, none);
    width: calc(var(--ctr-tooltip-pointer-height) * 1.41);
    height: calc(var(--ctr-tooltip-pointer-height) * 1.41);
    background: var(--ctr-tooltip-background-color, $background);
    border-top-color: transparent;
    border-inline-end-color: transparent;
    box-shadow:
      var(--ctr-tooltip-shadow-1-offset-x, 0px) var(--ctr-tooltip-shadow-1-offset-y, 2px)
        var(--ctr-tooltip-shadow-1-blur, 6px) var(--ctr-tooltip-shadow-1-spread, 0px)
        var(--ctr-tooltip-shadow-1-color, rgba(0, 0, 0, 0.1)),
      var(--ctr-tooltip-shadow-2-offset-x, 0px) var(--ctr-tooltip-shadow-2-offset-y, 1px)
        var(--ctr-tooltip-shadow-2-blur, 2px) var(--ctr-tooltip-shadow-2-spread, 0px)
        var(--ctr-tooltip-shadow-2-color, transparent);
    align-self: center;
    position: absolute;
    inset-inline-end: 100%;
    top: 50%;
    z-index: 300;
    transform: translate(calc(var(--ctr-tooltip-pointer-height) / 1.41), calc(var(--ctr-tooltip-pointer-height) / -1.41)) rotate(45deg);
    clip-path: polygon(-100% -100%, -100% 200%, 200% 200%);
  }

  .sv-svg-icon use {
    fill: var(--ctr-toolbox-item-icon-color-hovered, $primary);
  }
}

.svc-toolbox__item-title {
  @include ctrSmallBoldFont;

  display: block;
  padding-inline-start: calcSize(1);
  padding-inline-end: calcSize(1.5);
  white-space: nowrap;
  color: var(--ctr-toolbox-item-text-color, $foreground-light);
}

.svc-toolbox__tool:hover,
.svc-toolbox__tool:focus-within {
  .svc-toolbox__item-banner {
    display: var(--ctr-toolbox-item-banner-display, flex);
    opacity: 1;
    pointer-events: initial;

    // .svc-toolbox__item-title {
    //   padding-inline-start: calcSize(4);
    // }
  }
}

.svc-toolbox__tool--disabled {
  pointer-events: none;

  use {
    opacity: var(--ctr-toolbox-item-icon-opacity-disabled, 0.15);
    fill: var(--ctr-toolbox-item-icon-color-disabled, rgba(0, 0, 0, 0.91));
  }
  .svc-toolbox__item-title {
    opacity: var(--ctr-toolbox-item-text-opacity-disabled, 0.25);
  }
}

.svc-toolbox__item:focus,
.svc-toolbox__tool--has-subitems:hover > .svc-toolbox__tool-content > .svc-toolbox__item,
.svc-toolbox__item:hover {
  overflow: visible;
  background-color: var(--ctr-toolbox-item-background-color-hovered, $background);

  box-shadow:
    var(--ctr-toolbox-item-shadow-hovered-1-offset-x, 0px) var(--ctr-toolbox-item-shadow-hovered-1-offset-y, 2px)
      var(--ctr-toolbox-item-shadow-hovered-1-blur, 6px) var(--ctr-toolbox-item-shadow-hovered-1-spread, 0px)
      var(--ctr-toolbox-item-shadow-hovered-1-color, rgba(0, 0, 0, 0.1)),
    var(--ctr-toolbox-item-shadow-hovered-2-offset-x, 0px) var(--ctr-toolbox-item-shadow-hovered-2-offset-y, 1px)
      var(--ctr-toolbox-item-shadow-hovered-2-blur, 2px) var(--ctr-toolbox-item-shadow-hovered-2-spread, 0px)
      var(--ctr-toolbox-item-shadow-hovered-2-color, transparent);
  transition: 0.1s ease-in-out;

  .svc-toolbox--compact {
    .svc-toolbox__item:focus,
    .svc-toolbox__tool--has-subitems:hover > .svc-toolbox__tool-content > .svc-toolbox__item,
    .svc-toolbox__item:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .svc-toolbox__item-title {
    color: var(--ctr-toolbox-item-text-color-hovered, $foreground);
  }

  .sv-svg-icon use {
    fill: var(--ctr-toolbox-item-icon-color-hovered, $primary);
  }
}

.svc-toolbox__tool .sv-dots__item:hover:enabled,
.svc-toolbox__tool .sv-dots__item:focus:enabled,
.svc-toolbox__tool .sv-dots__item.sv-action-bar-item--pressed {
  background-color: var(--ctr-toolbox-button-background-color-hovered, $primary-light);
  border-radius: var(--ctr-toolbox-item-corner-radius, calcSize(12.5));
  box-shadow: none;

  .sv-svg-icon use {
    fill: var(--ctr-toolbox-item-icon-color-hovered, $primary);
  }
}
.svc-toolbox__item-submenu-button {
  position: absolute;
  inset-inline-end: var(--ctr-toolbox-item-padding-right, calcSize(1.5));
  margin-inline-end: var(--ctr-toolbox-item-submenu-button-margin-right, 0);
  top: calc(var(--ctr-toolbox-item-padding-top, var(--dft-toolbox-item-padding-top)) + var(--ctr-toolbox-gap, var(--dft-toolbox-gap)));
  display: inline-flex;
  padding: var(--ctr-toolbox-item-submenu-button-padding-top, calcSize(0.5))
    var(--ctr-toolbox-item-submenu-button-padding-right, calcSize(0.5)) 
    var(--ctr-toolbox-item-submenu-button-padding-bottom, calcSize(0.5))
    var(--ctr-toolbox-item-submenu-button-padding-left, calcSize(0.5));
  box-sizing: border-box;
  align-items: center;
  border-radius: var(--ctr-toolbox-item-submenu-button-corner-radius, 1024px);
  background-color: var(--ctr-toolbox-item-submenu-button-background-color, $background-dim);
  transition: background-color $creator-transition-duration;
  &:hover {
    background-color: var(--ctr-toolbox-item-submenu-button-background-color-hovered, $background);
  }
  svg {
    fill: var(--ctr-toolbox-item-submenu-button-icon-color, $layer-1-foreground-50);
    width: var(--ctr-toolbox-item-submenu-button-icon-width, calcSize(2));
    height: var(--ctr-toolbox-item-submenu-button-icon-height, calcSize(2));
  }
}

.svc-toolbox-popup {
  .sv-list__item {
    &:first-of-type {
      .sv-list__item-separator {
        display: none;
      }
    }

    .sv-list__item-body {
      padding-top: calcSize(1.5);
      padding-bottom: calcSize(1.5);
      padding-inline-start: calcSize(2);
      padding-inline-end: calcSize(5);
    }
  }
}

.svc-toolbox__tool.svc-toolbox__tool--pressed {
  .svc-toolbox__item {
    background: var(--ctr-toolbox-item-background-color-pressed, inherit);
    box-shadow: var(--ctr-toolbox-item-shadow-pressed-offset-x, 0px)
      var(--ctr-toolbox-item-shadow-pressed-offset-y, 1px) var(--ctr-toolbox-item-shadow-pressed-blur, 2px)
      var(--ctr-toolbox-item-shadow-pressed-spread, 0px) var(--ctr-toolbox-item-shadow-pressed-color, rgba(0, 0, 0, 0));
  }

  .svc-toolbox__item-title {
    color: var(--ctr-toolbox-item-text-color-pressed, $foreground);
    opacity: var(--ctr-toolbox-item-text-opacity-pressed, 0.5);
  }

  .sv-svg-icon use {
    fill: var(--ctr-toolbox-item-icon-color-pressed, $foreground);
    opacity: var(--ctr-toolbox-item-icon-opacity-pressed, 0.5);
  }

  .svc-toolbox__item.svc-toolbox__item-subtype {
    opacity: unset;
  }
}

.svc-toolbox-subtypes {
  .sv-popup__container {
    margin-top: calc(
      0px - var(--ctr-toolbox-submenu-group-margin-top, 0px) -
        var(--ctr-toolbox-item-padding-top, var(--dft-toolbox-item-padding-top))
    );
    padding: 0;

    padding-inline-start: calc(
      var(--ctr-toolbox-item-padding-right, var(--dft-toolbox-item-padding-right)) + 
      var(--ctr-toolbox-item-submenu-button-margin-right, 0px) + 
      var(--ctr-toolbox-group-padding-right, var(--dft-toolbox-group-padding-right)) +
      var(--ctr-toolbox-border-width-right, 0px));

    box-shadow: unset;
    background: unset;
  }

  .sv-list__container {
    flex-wrap: wrap;
    column-gap: calcSize(2);
  }

  .sv-popup__body-content {
    border-radius: var(--ctr-toolbox-submenu-corner-radius, calcSize(1));
    box-shadow:
      var(--ctr-toolbox-submenu-shadow-1-offset-x, 0px) var(--ctr-toolbox-submenu-shadow-1-offset-y, 2px)
        var(--ctr-toolbox-submenu-shadow-1-blur, 8px) var(--ctr-toolbox-submenu-shadow-1-spread, 0px)
        var(--ctr-toolbox-submenu-shadow-1-color, transparent),
      var(--ctr-toolbox-submenu-shadow-2-offset-x, 0px) var(--ctr-toolbox-submenu-shadow-2-offset-y, 16px)
        var(--ctr-toolbox-submenu-shadow-2-blur, 32px) var(--ctr-toolbox-submenu-shadow-2-spread, 0px)
        var(--ctr-toolbox-submenu-shadow-2-color, transparent);
    background: var(--ctr-toolbox-submenu-background-color, unset);
    padding: 0;
  }

  .sv-popup__scrolling-content {
    padding-top: calc(var(--ctr-toolbox-submenu-group-margin-top, 0px));
    padding-inline-end: var(--ctr-toolbox-submenu-group-margin-right, 0px);
    padding-bottom: var(--ctr-toolbox-submenu-group-margin-bottom-last, 0px);
    padding-inline-start: var(--ctr-toolbox-submenu-group-margin-left, 0px);
    overflow: visible;
  }

  .sv-list__item:hover > .sv-list__item-body,
  .sv-list__item:focus > .sv-list__item-body {
    background-color: unset;
  }

  .svc-toolbox__item.svc-toolbox__item-subtype {
    opacity: var(--ctr-toolbox-item-opacity-submenu, 0.85);
    overflow: hidden;
    border-radius: var(--ctr-toolbox-item-corner-radius, calcSize(12.5));
    background: var(--ctr-toolbox-item-background-color-submenu, $layer-2-background-500);
    box-shadow:
      var(--ctr-toolbox-item-shadow-hovered-1-offset-x, 0px) var(--ctr-toolbox-item-shadow-hovered-1-offset-y, 2px)
        var(--ctr-toolbox-item-shadow-hovered-1-blur, 6px) var(--ctr-toolbox-item-shadow-hovered-1-spread, 0px)
        var(--ctr-toolbox-item-shadow-hovered-1-color, rgba(0, 0, 0, 0.1)),
      var(--ctr-toolbox-item-shadow-hovered-2-offset-x, 0px) var(--ctr-toolbox-item-shadow-hovered-2-offset-y, 1px)
        var(--ctr-toolbox-item-shadow-hovered-2-blur, 2px) var(--ctr-toolbox-item-shadow-hovered-2-spread, 0px)
        var(--ctr-toolbox-item-shadow-hovered-2-color, rgba(0, 0, 0, 0));
    transition: 0.1s ease-in-out;
    padding: 
      var(--ctr-toolbox-item-padding-top-submenu, calcSize(1.5))
      var(--ctr-toolbox-item-padding-right, calcSize(2))
      var(--ctr-toolbox-item-padding-bottom-submenu, calcSize(1.5))
      var(--ctr-toolbox-item-padding-left-submenu, calcSize(2));
    align-self: flex-start;
    min-width: var(--ctr-toolbox-submenu-item-min-width, initial);
    box-sizing: border-box;

    .svc-toolbox__item-title {
      color: var(--ctr-toolbox-item-text-color-submenu, $layer-2-foreground-100);
    }
  }

  .svc-toolbox__tool:hover,
  .svc-toolbox__item:focus {
    .svc-toolbox__item-subtype {
      background: var(--ctr-toolbox-item-background-color-hovered, $layer-1-background-500);
      opacity: unset;

      .svc-toolbox__item-title {
        color: var(--ctr-toolbox-item-text-color-hovered, $layer-1-foreground-100);
      }
    }
  }

  .svc-toolbox__item-title {
    padding: 0;
  }
}

.svc-toolbox__tool--dots {
  align-self: flex-start;
}
.svc-toolbox__item--dots {
  padding-inline-end: var(--ctr-toolbox-item-padding-left, calcSize(1.5));
  margin-top: calcSize(0.5);
}
.svc-toolbox--compact {
  .svc-toolbox__item-submenu-button {
    display: none;
  }
  .svc-toolbox__tool {
    padding: 0px var(--ctr-toolbox-item-margin-horizontal-no-text, calcSize(0.5));
    align-self: flex-start;
    pointer-events: all;
  }
  .svc-toolbox__search-button {
    padding-inline-start: var(--ctr-toolbox-item-margin-horizontal-no-text, calcSize(0.5));
  }
  .svc-toolbox__item {
    padding-inline-start: var(--ctr-toolbox-item-padding-left-no-text, calcSize(1));
    padding-inline-end: var(--ctr-toolbox-item-padding-right-no-text, calcSize(1));
    box-sizing: border-box;
    align-self: flex-start;
  }
  .svc-toolbox__tool--dots {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
  .svc-toolbox__item--dots {
    padding-inline-end: var(--ctr-toolbox-item-padding-right, calcSize(1.5));
    padding-inline-start: var(--ctr-toolbox-item-padding-left, calcSize(1.5));
  }
}
