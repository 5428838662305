.svc-btn {
  display: flex;
  padding: var(--ctr-button-padding-vertical, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))))) var(--ctr-button-padding-horizontal, calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  justify-content: center;
  align-items: center;
  background-color: var(--ctr-button-background-color, var(--sjs-general-backcolor, var(--background, #fff)));
  border-radius: var(--ctr-button-corner-radius, calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  cursor: pointer;
  user-select: none;
  outline: solid calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) transparent;
  transition: background-color var(--sjs-creator-transition-duration, 150ms);
  box-shadow: var(--ctr-button-shadow-offset-x, 0px) var(--ctr-button-shadow-offset-y, 1px) var(--ctr-button-shadow-blur, 2px) var(--ctr-button-shadow-spread, 0px) var(--ctr-button-shadow-color, var(--ctr-shadow-small-color, rgba(0, 0, 0, 0.15)));
}

.svc-btn__text {
  --default-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  --ctr-default-line-height: calc(1.5 * var(--default-font-size));
  font-family: var(--ctr-font-family, var(--sjs-font-family, var(--font-family)));
  font-size: var(--ctr-font-default-size, var(--default-font-size, 16px));
  font-style: normal;
  font-weight: 400;
  line-height: var(--ctr-font-default-line-height, var(--ctr-default-line-height, 24px));
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  color: var(--ctr-button-text-color, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}

.svc-btn:hover {
  background-color: var(--ctr-button-background-color-hovered, var(--sjs-questionpanel-hovercolor, var(--sjs-general-backcolor-dark, rgb(248, 248, 248))));
}

.svc-btn:focus {
  box-shadow: 0 0 0 var(--ctr-button-border-width-focused, 2px) var(--ctr-button-border-color-focused, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}

.svc-action-button {
  --default-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  --ctr-default-line-height: calc(1.5 * var(--default-font-size));
  font-family: var(--ctr-font-family, var(--sjs-font-family, var(--font-family)));
  font-size: var(--ctr-font-default-size, var(--default-font-size, 16px));
  font-style: normal;
  font-weight: 400;
  line-height: var(--ctr-font-default-line-height, var(--ctr-default-line-height, 24px));
  font-weight: 600;
  display: inline-block;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--ctr-survey-action-button-text-color-positive, var(--sjs-primary-backcolor, var(--primary, #19b394)));
  border: calc(0.25 * (var(--sjs-base-unit, var(--base-unit, 8px)))) solid transparent;
}
.svc-action-button .svc-text {
  color: var(--ctr-survey-action-button-text-color-positive, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}
.svc-action-button:focus, .svc-action-button:hover {
  background-color: var(--ctr-survey-action-button-background-color-hovered-positive, var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1))));
  outline: none;
}

.svc-action-button--selected {
  border-color: var(--ctr-survey-action-button-text-color-positive, var(--sjs-primary-backcolor, var(--primary, #19b394)));
}

.svc-action-button--disabled {
  opacity: var(--ctr-survey-action-button-opacity-disabled, 0.25);
  color: var(--ctr-survey-action-button-text-color-disabled, var(--sjs-general-forecolor, var(--foreground, #161616)));
}