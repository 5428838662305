@import "../variables.scss";

.svc-notifier.svc-notifier--shown {
  visibility: visible;
  opacity: var(--ctr-notification-opacity, 0.75);
}

.svc-notifier {
  @include ctrSmallBoldFont;

  position: absolute;
  bottom: calcSize(1);
  left: calcSize(1);
  background: var(--ctr-notification-background-color, $background);
  opacity: 0;
  padding: calcSize(1) calcSize(2);
  box-shadow: $shadow-medium;
  border-radius: calcSize(0.5);
  color: var(--ctr-notification-text-color, $foreground);
  min-width: calcSize(30);
  text-align: center;
  z-index: 1600; //todo: should overlap popup

  visibility: hidden;
}

.svc-notifier.svc-notifier--error {
  background-color: var(--ctr-notification-background-color-error, $red);
  color: var(--ctr-notification-text-color-error, $red-forecolor);
  opacity: var(--ctr-notification-opacity-error, 1);
}

.svc-creator--mobile {
  .svc-notifier {
    left: 0;
    bottom: calcSize(6);
    width: 100%;
    box-sizing: border-box;
    opacity: 1;
    z-index: 1000;
  }
}