.spg-table-wrapper {
  border: var(--ctr-data-table-border-width, 1px) solid var(--ctr-data-table-border-color, $border);
  border-radius: var(--ctr-data-table-corner-radius, 0px);
  overflow: hidden;
}

.spg-table {
  width: 100%;
  background-color: var(--ctr-data-table-background-color, $background);
  border-collapse: collapse;

  tr {
    border-bottom: var(--ctr-data-table-row-border-width, 1px) solid var(--ctr-data-table-row-border-color, $border);
    background-color: var(--ctr-data-table-row-background-color, $background);
  }
  tbody > tr:last-of-type {
    border-bottom: var(--ctr-data-table-row-border-width-last-row, 0px);
  }
}

.spg-table__cell {
  padding: 0;

  .spg-checkbox {
    margin: 0 calcSize(2);
  }
}

.spg-table__cell--detail-panel {
  background: var(--ctr-data-table-background-color, $background-dim);

  .spg-panel__content {
    box-shadow: none;
  }
}

.spg-table__cell:not(.spg-table__cell--detail-panel):not(.spg-table__cell--actions):first-of-type {
  padding-left: 8px;
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-input {
  appearance: none;
  box-shadow: none;
  border-radius: var(--ctr-data-table-cell-corner-radius-focused, calcSize(0.5));
  background-color: var(--ctr-data-table-row-background-color, transparent);
  height: auto;
  padding: var(--ctr-data-table-cell-padding-top, calcSize(1)) var(--ctr-data-table-cell-padding-right, calcSize(1))
    var(--ctr-data-table-cell-padding-bottom, calcSize(1)) var(--ctr-data-table-cell-padding-left, calcSize(1));

  &[type="color"] {
    padding-right: 0;
  }

  &:focus,
  &:focus-within {
    box-shadow: inset 0 0 0 2px var(--ctr-data-table-cell-border-color-focused, $primary);
  }
}

.spg-table__cell:not(.spg-table__cell--detail-panel) .spg-dropdown_chevron-button {
  position: static;
}

.spg-table__cell:not(.spg-table__cell--detail-panel) {
  .spg-dropdown_select-wrapper {
    display: flex;
    align-items: center;
  }

  .spg-dropdown {
    width: max-content;
    padding-right: calcSize(3);
    min-height: auto;
  }

  .spg-dropdown_chevron-button {
    --tmp-dropdown-button-margin: calc(-1 * var(--ctr-data-table-cell-drop-down-arrow-width));
    width: var(--ctr-data-table-cell-drop-down-arrow-width, calcSize(3));
    height: var(--ctr-data-table-cell-drop-down-arrow-height, calcSize(3));
    margin-inline-start: var(--tmp-dropdown-button-margin, calcSize(-3));
    padding: var(--ctr-data-table-cell-padding-top, calcSize(1)) 0
      var(--ctr-data-table-cell-padding-bottom, calcSize(1)) 0;

    &:hover, &:focus {
      background: transparent;
    }
  }

  .sd-dropdown_chevron-button-svg {
    opacity: var(--ctr-data-table-cell-drop-down-arrow-opacity, 0.5);
    
    use {
      fill: var(--ctr-data-table-cell-drop-down-arrow-color, $foreground-light);
    }
  }
}

.spg-table__cell:not(.spg-table__cell--detail-panel):first-of-type .spg-text {
  padding-left: calcSize(2);
}

.spg-table__cell--actions:first-of-type {
  width: calcSize(5);
}

.spg-table__cell--actions:last-of-type {
  .spg-action-bar {
    justify-content: flex-end;
    padding-inline-end: var(--ctr-data-table-row-padding-right, calcSize(0.5));
  }
}

.spg-table__cell--actions > .spg-matrixdynamic__drag-element {
  display: inline-block;
  margin-top: calcSize(0.5);
  cursor: move;
}

.spg-table__cell--header {
  background: var(--ctr-data-table-row-background-color-header, $background-dim);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  padding: var(--ctr-data-table-row-padding-top-header, #{calcSize(0.5)})
    var(--ctr-data-table-cell-padding-right, #{calcSize(1)})
    var(--ctr-data-table-row-padding-bottom-header, #{calcSize(0.5)})
    var(--ctr-data-table-cell-padding-left, #{calcSize(1)});

  @include ctrSmallFont;
  color: var(--ctr-data-table-cell-text-color-header, $foreground-light);
  
  span {
    display: none;
  }

  .sv-string-viewer {
    display: inline;
    white-space: unset;
  }
}
.spg-table__question-wrapper {
  box-sizing: border-box;
  padding: var(--ctr-data-table-row-padding-top, calcSize(0.5)) var(--ctr-data-table-row-padding-right, 0)
    var(--ctr-data-table-row-padding-bottom, calcSize(0.5)) var(--ctr-data-table-row-padding-left, 0);
}

.spg-table__row--leave,
.spg-table__row--enter {
  animation-name: empty;
  --move-whole-animation-duration: calc(var(--move-animation-duration) + var(--move-animation-delay));
  --fade-whole-animation-duration: calc(var(--fade-animation-duration) + var(--fade-animation-delay));
  animation-duration: max(var(--fade-whole-animation-duration), var(--move-whole-animation-duration));
  & > td {
    & > div {
      animation-name: fadeIn, moveInWithOverflow;
      opacity: 0;
      animation-direction: var(--animation-direction);
      animation-timing-function: var(--animation-timing-function);
      animation-fill-mode: forwards;
      animation-duration: var(--fade-animation-duration), var(--move-animation-duration), var(--move-animation-duration);
      animation-delay: var(--fade-animation-delay), var(--move-animation-delay), var(--move-animation-delay);
    }
  }
}
.spg-table__row--enter {
  --move-animation-delay: 0s;
  --move-animation-duration: #{$spg-matrix-row-move-in-duration};
  --fade-animation-duration: #{$spg-matrix-row-fade-in-duration};
  --fade-animation-delay: #{$spg-matrix-row-fade-in-delay};
  --animation-direction: normal;
  --animation-timing-function: #{$ease-out};
}
.spg-table__row--leave {
  --move-animation-delay: #{$spg-matrix-row-move-out-delay};
  --move-animation-duration: #{$spg-matrix-row-move-out-duration};
  --fade-animation-duration: #{$spg-matrix-row-fade-out-duration};
  --fade-animation-delay: 0s;
  --animation-direction: reverse;
  --animation-timing-function: #{$reverse-ease-out};
}
.spg-table__row--detail {
  &.spg-table__row--enter {
    --move-animation-delay: 0s;
    --move-animation-duration: #{$spg-matrix-detail-row-move-in-duration};
    --fade-animation-duration: #{$spg-matrix-detail-row-fade-in-duration};
    --fade-animation-delay: #{$spg-matrix-detail-row-fade-in-delay};
    --animation-direction: normal;
    --animation-timing-function: #{$ease-out};
  }
  &.spg-table__row--leave {
    --move-animation-delay: #{$spg-matrix-detail-row-move-out-delay};
    --move-animation-duration: #{$spg-matrix-detail-row-move-out-duration};
    --fade-animation-duration: #{$spg-matrix-detail-row-fade-out-duration};
    --fade-animation-delay: 0s;
    --animation-direction: reverse;
    --animation-timing-function: #{$reverse-ease-out};
  }
}

.svc-creator--disable-animations {
  .spg-table__row--enter,
  .spg-table__row--leave {
    animation: none;
  }
}